/* This is referenced by the worker.js script via jsDelivr
 * URL: https://cdn.jsdelivr.net/gh/spencerwooo/Substats@{COMMIT_HASH}/styles/default.min.css
 * You should always commit this CSS file first before referencing it in `index.js`
 */

html {
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 700px;
  font-family: 'Fira Code', Consolas, Monaco, monospace, Noto Sans CJK SC, Sarasa Gothic SC, Microsoft YaHei, monospace,
    Apple Color Emoji, Segoe UI Emoji;
  background-color: #fafaff;
}

@media only screen and (max-width: 700px) {
  body {
    height: 100%;
    padding: 2.5rem 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #banner {
    max-width: 100%;
  }

  #code-banner-container {
    max-width: 100%;
  }
}

h6 {
  font-size: 0.85em;
  color: #6a737d;
  font-weight: 600;
}

#code-banner-container {
  perspective: 180px;
}

#code-banner {
  position: relative;
  padding: 1rem 2rem 2rem 1.5rem;
  margin: 2rem 0;
  overflow: auto;
  font-size: 0.9em;
  line-height: 1.45;
  background-color: #282c34;
  box-shadow: 2em 4em 6em -2em rgba(0, 0, 0, 0.5), 1em 2em 3.5em -2.5em rgba(0, 0, 0, 0.5);
  border-radius: 0.5em;
  -webkit-transition: transform 0.3s ease, box-shadow 0.3s ease;
  -moz-transition: transform 0.3s ease, box-shadow 0.3s ease;
  -o-transition: transform 0.3s ease, box-shadow 0.3s ease;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

#code-banner a:hover {
  text-decoration: none;
}

#code-banner code {
  font-family: 'Fira Code', Consolas, Monaco, monospace;
  color: #fff;
  padding: 0;
  background-color: transparent;
  border-radius: 0;
  overflow-wrap: normal;
}

#code-banner code span {
  color: #fff;
}

#code-banner code span.accent {
  color: #f48120;
}

#code-banner code span.source-highlight {
  color: #00b0d8;
}

#code-banner code span.query-highlight {
  color: #01ad9f;
}

#code-banner code span.token {
  transition: opacity 0.3s ease;
  opacity: 0.7;
}

#code-banner:hover code span.token {
  opacity: 1;
}

#code-banner code em {
  text-transform: uppercase;
  font-weight: 400;
  font-style: normal;
  font-size: 0.75em;
  color: hsla(0, 0%, 100%, 0.4);
}

#code-banner #code-class {
  position: absolute;
  bottom: 0;
  right: 0;
  color: hsla(0, 0%, 100%, 0.4);
  font-size: 0.75rem;
  margin: 0 1rem 1rem 0;
}

strong,
em {
  font-weight: 600;
}

footer {
  margin-top: 2rem;
  text-align: center;
  font-size: 0.75em;
  color: #6a737d;
}

.first-line {
  margin-top: 3rem;
}

.second-line {
  margin-top: 0.4rem;
}

a {
  color: #faad3f;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
